<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="终止订单"
      width="500px"
      @ok="handleSubmit"
    >
      <span>确定终止吗？终止后，订单下未完成的任务将自动取消，并且无法进行恢复，请慎重操作。</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:50px;"
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { terminationOrder } from '@/api/order'

export default {
  name: 'TerminationOrder',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'termination_order' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {

  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          terminationOrder(Object.assign({ order_id: this.id }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
